.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: white !important;
    background: #fdb515 !important;
    border: none !important;
    border-radius: 0.25rem !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    /* padding: 0 !important; */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #fdb515 !important;
    font-weight: bold;
}

.pill-row {
    border-radius: 0.25rem;
    box-shadow: -1px 1px 20px 0px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: -1px 1px 20px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: -1px 1px 20px 0px rgba(0, 0, 0, 0.12);
}

.pill-column {
    border-right: 1px solid #fdb515;
}

.pdfHighlight {
    line-height: 2.5rem;
    color: #fdb515 !important;
    margin-right: 0.5rem;
}

.pdfHighlight:hover {
    color: #e31a52 !important;
}

.pdfHighlight-line-space {
    color: #fdb515 !important;
    margin-right: 0.5rem;
}

.pdfHighlight-line-space:hover {
    color: #e31a52 !important;
}

.tab-content {
    width: 100% !important;
}
.grid-container {
    margin-right: 10rem;
    margin-left: 10rem;
    display: grid;
    overflow: auto;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0 !important;
    grid-row-gap: 0 !important;
    padding: 10px;
}
/* .grid-item:hover {
    background: orange;
    border: none;
}
.grid-item:hover > p {
    color: white !important;
} */
.grid-item {
    /* border-radius: 0.25rem; */
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(77, 77, 77, 0.8);
    padding: 10px;
    font-size: 1rem;
    text-align: center;
}

.OtherModal >>> .modal.show .modal-dialog {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
}

@media (max-width: 1700px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 5rem;
        margin-left: 5rem;
    }
}

@media (max-width: 1600px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 5rem;
        margin-left: 5rem;
    }
}

@media (max-width: 1100px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 0rem;
        margin-left: 0rem;
        /* margin-right: 10rem;
        margin-left: 10rem; */
    }
}

@media (max-width: 600px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 0rem;
        margin-left: 0rem;
    }
}

@media (max-width: 400px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 0rem;
        margin-left: 0rem;
    }
}
