.newsUpdatesBackground {
    background-image: url("https://images.pexels.com/photos/3944417/pexels-photo-3944417.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20rem;
}

.newsUpdates {
    /* color: #000; */
    line-height: 14rem;
}

.new-newsUpdate {
    animation: newnewsUpdate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 0.8rem;
    color: white;
    /* font-weight: bold; */
}
.important-newsUpdate {
    animation: importantnewsUpdate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    /* font-weight: bold; */
}

/* MARQUEE */

.container {
    width: 100%;
    height: 16rem;
    margin: 0;
    overflow: hidden;
    background: #e9e9e9;
    position: relative;
    box-sizing: border-box;
}

.marquee {
    top: 6em;
    position: relative;
    box-sizing: border-box;
    animation: marquee 5s linear infinite;
}

.marquee:hover {
    animation-play-state: paused;
}

/* Make it move! */
@keyframes marquee {
    0% {
        top: 15em;
    }
    100% {
        top: -9em;
    }
}

/* Make it look pretty */
.microsoft .marquee {
    margin: 0;
    padding: 0 1em;
    line-height: 1.5em;
    font: 1em "Segoe UI", Tahoma, Helvetica, Sans-Serif;
}

.microsoft:before,
.microsoft::before,
.microsoft:after,
.microsoft::after {
    left: 0;
    z-index: 1;
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 2em;
    /* background-image: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0)); */
}

.microsoft:after,
.microsoft::after {
    bottom: 0;
    transform: rotate(180deg);
}

.microsoft:before,
.microsoft::before {
    top: 0;
}

/* MARQUEE ENDS */

@keyframes importantnewsUpdate {
    0% {
        color: #e31a52;
    }
    33% {
        color: white;
    }
    66% {
        color: white;
    }
    100% {
        color: #e31a52;
    }
}

@keyframes newnewsUpdate {
    0% {
        background-color: red;
    }
    33% {
        background-color: orange;
    }
    100% {
        background-color: #e31a52;
    }
}

@media only screen and (max-width: 575px) {
    .newsUpdatesBackground {
        height: 10rem;
    }
    .newsUpdates {
        /* color: #000; */
        line-height: 8rem;
    }
}
