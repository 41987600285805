.productListTitle {
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.ProductContent {
  white-space: pre-wrap;
  text-align: left;
}

.cardlayout-margin {
  margin-bottom: 3rem !important;
}

.bsProductImage {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 0.25rem;
}

.Documents-Required > ol > li {
  margin: -0.5rem;
}

.card-title {
}

.product-list-card > .card-image-size {
  /* max-width: 20rem;
  max-height: 10rem;
  height: 10rem; */
  padding: 3rem;
  margin: auto;
  border-radius: 0.25rem !important;
  align-items: center;
  justify-content: center;
}
.product-list-card > .card-img-overlay {
  border: none;
  border-radius: 0.25rem !important;
  transition: 1s ease;
  background-color: rgba(227, 26, 82);
  box-shadow: 0px 16px 19px -6px rgba(0, 0, 0, 0.34);
  color: white;
  transition: 1s ease;
}

.ProductLabelTop {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.product-list-card > .card-img-overlay:hover {
  background-color: white;
  /* box-shadow: 0px 16px 19px -6px rgba(0, 0, 0, 0.34); */
  color: black;
  transition: 1s ease;
}
/* .productListTitle{
	mar
} */
.breadcrumbs a {
  color: rgb(227, 26, 82) !important;
  padding-left: 1.125rem;
}

.breadcrumbs {
  font-weight: 500;
}

.Productsheading > p {
  color: #666;
  display: inline-block;
  margin: 0;
  /* text-transform: uppercase; */
}
.Productsheading > p:after {
  display: block;
  content: "";
  border-bottom: solid 3px rgb(227, 26, 82);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.Productsheading > p:hover:after {
  transform: scaleX(1);
}
.Productsheading > p.fromRight:after {
  transform-origin: 100% 50%;
}
.Productsheading {
  margin-bottom: 6rem;
}
.perProductImage {
  max-height: 25rem;
  object-fit: cover;
  filter: brightness(0.7);
}
.perProductImageOverlay {
  /* padding-top: 11rem !important; */
  color: white;
  font-weight: bold;
  text-align: left;
  width: 90%;
  margin: auto;
}
.perProductCardText {
  white-space: pre-line;
  text-align: left;
}
.product-text {
  text-align: left;
  margin: auto;
}
.ProductList-wrapper {
  background-color: rgb(253, 181, 21) !important;
}
.ProductList-wrapper > .Base-wrapper {
  background-color: rgb(253, 181, 21) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 3rem;
}
.ProductTitle {
  line-height: 35rem;
  color: white;
  font-weight: bold;
}

.tab-content > .active {
  opacity: 1 !important;
}

.ProductDetailsTab {
  width: 100%;
  /* padding-left: 1.125rem; */
  margin-bottom: 0 !important;
}

.nav-tabs {
  padding-bottom: 0rem;
  border-bottom: 2px solid #fdb515 !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.ProductDetailsTab > .nav-tabs > a {
  margin: auto;
  width: 25%;
  color: black;
  /* margin-bottom: 0; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  background: #e31a52 !important;
  border: none !important;
  border-radius: 0.25rem !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 1rem;
}

@media only screen and (max-width: 767px) {
  .ProductDetailsTab > .nav-tabs > a {
    margin: auto;
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .ProductDetailsTab > .nav-tabs > a {
    margin: auto;
    width: 100%;
  }
  .cardlayout-margin {
    margin-bottom: 0rem !important;
  }
}
