.RepcoBranches {
  height: 20rem;
  line-height: 20rem;
  color: white;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: 1s ease;
  cursor: pointer;
}

.branch-custom-row {
  width: 50%;
}

.RepcoBranches > a {
  color: white;
}

.RepcoBranches > a:hover {
  color: white !important;
}

.RepcoBranches a:link {
  text-decoration: none;
}

.RepcoBranches a:visited {
  text-decoration: none;
}

.RepcoBranches a:hover {
  text-decoration: underline;
}

.RepcoBranches a:active {
  text-decoration: underline;
}

.SatelliteCenters {
  height: 20rem;
  line-height: 20rem;
  color: white;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: 1s ease;
  cursor: pointer;
}

.SatelliteCenters > a {
  color: white;
}

.SatelliteCenters > a:hover {
  color: white !important;
}

.SatelliteCenters > a:link {
  text-decoration: none;
}

.SatelliteCenters > a:visited {
  text-decoration: none;
}

.SatelliteCenters > a:hover {
  text-decoration: underline;
}

.SatelliteCenters > a:active {
  text-decoration: underline;
}

.RepcoBranches:hover {
  background: black;
  color: white;
  transition: 1s ease;
}

/* .SatelliteCenters:hover {
  background: black;
  color: white;
  transition: 1s ease;
} */

.BranchCardImage {
  min-height: 16rem !important;
  min-width: 11rem;
  object-fit: cover;
  height: 16rem;
}

.alternateColor .card {
  height: 27rem;
  font-weight: 600;
}
a:hover {
  color: black !important;
  text-decoration: none !important;
}

a {
  color: black !important;
  text-decoration: none !important;
  background-color: transparent;
}

.alternateColor:nth-child(odd) .card:hover {
  background: #fdb515;
  color: white;
  cursor: pointer;
  transition: 0.25s ease;
}

/* .card:nth-child(1n-1):hover {
  background: #e31a52;
  color: white;
  cursor: pointer;
  transition: 0.5s ease;
} */

.card-body {
  flex: none !important;
  min-height: 1px;
  padding: 1.25rem;
  margin: auto;
  padding-top: 0 !important;
}

.BranchNetworkHeading {
  margin: 3rem 0rem;
}
.BranchInputSet {
  margin-bottom: 3rem;
}
.BranchStateInput {
  border: 2px solid #fdb515;
  border-radius: 30px;
  color: black !important;
  padding: 0.5rem 1.25rem;
  margin-right: 1rem;
  width: 45%;
}
.BranchCityInput {
  border: 2px solid #fdb515;
  border-radius: 30px;
  color: black !important;
  padding: 0.5rem 1.25rem;
  width: 45%;
}
.SATCenterButton {
  transform: translateY(-1px);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05),
    0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
  height: 3rem;
  background: #fdb515 !important;
  border: none !important;
  color: black !important;
  font-weight: 500 !important;
}

.font-tint {
  color: rgb(227, 26, 82);
}

@media only screen and (max-width: 522px) {
  .BranchStateInput {
    margin-bottom: 1rem;
    margin-right: 0rem;
  }
  .branch-custom-row {
    width: 80%;
  }
}

.form-control {
  height: 4rem !important;
}
