#photos {
  /* Prevent vertical gaps */

  -webkit-column-count: 3;
  -webkit-column-gap: 10px;
  -moz-column-count: 3;
  -moz-column-gap: 10px;
  column-count: 3;
  grid-column-gap: 10px;
  line-height: 7;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}
@media (max-width: 1600px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    line-height: 6;
  }
}
@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    line-height: 6;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    line-height: 8;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 9;
  }
}
@media (max-width: 400px) {
  #photos {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    line-height: 10;
  }
}

.pswp__caption__center {
  text-align: center !important;
  font-size: 2rem;
  margin-bottom: 2rem;
  max-width: 70%;
  line-height: 1;
}
