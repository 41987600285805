@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.App {
  text-align: center;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

input:focus {
  outline: 0px !important;
}

/* Wrapper and Font sizes Start */
.Base-wrapper {
  width: 90%;
  margin: 2rem auto;
}

.headings {
  font-size: 1.75rem !important;
  font-weight: bold !important;
  color: #e31a52;
}

.mid-size-content {
  font-size: 1.25rem !important;
  font-weight: bold !important;
}

.lower-mid-size-content {
  font-size: 1rem !important;
}

.small-size-content {
  font-size: 1rem !important;
}

.cursor-pointer {
  cursor: pointer;
}
/* Wrapper and Font sizes End */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.chatbox {
  position: fixed;
  width: fit-content;
  background: orange;
  bottom: 30rem;
  right: 1rem;
  height: 4rem;
  line-height: 4rem;
  border-radius: 7rem;
  cursor: pointer;
  z-index: 1000;
}

.chatButton {
  position: fixed;
  width: 4rem;
  background: orange;
  bottom: 2rem;
  right: 2rem;
  height: 4rem;
  line-height: 5rem;
  border-radius: 7rem;
  cursor: pointer;
}

.chatIcon {
  font-size: 2rem;
  color: white;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup chat - hidden by default */
.form-popup {
  /* display: none; */
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #fdb515;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  /* opacity: 0.8; */
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: #e31a52;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(0.95em + 0.1875rem) center !important;
}

@media only screen and (max-width: 1366px) and (min-width: 320px) {
  .headings {
    font-size: 1.5rem !important;
    font-weight: bold !important;
  }

  .mid-size-content {
    font-size: 1rem !important;
  }

  .lower-mid-size-content {
    font-size: 0.9rem !important;
  }

  .small-size-content {
    font-size: 0.75rem !important;
  }
}
