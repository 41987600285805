.announcementsBackground {
    background-image: url("http://134.209.154.158/backend/uploads/anouncement_2_2ab49bb4ce.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 25rem;
}

.announcements {
    /* color: #000; */
    line-height: 18rem;
}

.new-announcement {
    animation: newAnnouncement;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 0.8rem;
    color: white;
    font-weight: bold;
}
.important-announcement {
    animation: importantAnnouncement;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    font-weight: bold;
}

@keyframes importantAnnouncement {
    0% {
        color: #E31A52;
    }
    33% {
        color: black;
    }
    66% {
        color: black;
    }
    100% {
        color: #E31A52;
    }
}

@keyframes newAnnouncement {
    0% {
        background-color: red;
    }
    33% {
        background-color: orange;
    }
    100% {
        background-color: #e31a52;
    }
}

@media only screen and (max-width: 575px) {
    .announcementsBackground {
        height: 15rem;
    }
    .announcements {
        /* color: #000; */
        line-height: 12rem;
    }
}
