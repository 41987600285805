.EnquiryFormBase {
    position: absolute;
    background: white;
    padding: 2rem;
    width: -moz-fit-content;
    width: fit-content;
    left: 6rem;
    top: -8rem;
    border-radius: 0.25rem;
    min-width: 30rem;
    overflow: auto;
    max-height: 35rem;
    box-shadow: -1px 3px 16px 12px rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: -1px 3px 16px 12px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -1px 3px 16px 12px rgba(0, 0, 0, 0.26);
    transition: 1s ease-in;
}

.okButton {
    margin: auto;
    display: block;
    min-width: 6rem;
    background-color: rgb(253, 181, 21);
    /* text-transform: uppercase; */
    color: white;
    text-align: center;
    line-height: 12px;
    letter-spacing: 1px;
    padding: 1rem;
    border-radius: 0.25rem;
    border: none;
}

/* .modal.show .modal-dialog {
    position: absolute !important;
    left: 3rem !important;
    top: 5rem;
} */

.enquiryApplyButton {
    background-color: rgb(253, 181, 21);
    /* text-transform: uppercase; */
    color: white;
    text-align: center;
    line-height: 12px;
    letter-spacing: 1px;
    padding: 1rem;
    border-radius: 0.25rem;
    border: none;
    /* font-weight: bold; */
}

.enquiryCloseButton {
    background-color: rgb(227, 26, 82);
    /* text-transform: uppercase; */
    color: white;
    text-align: center;
    line-height: 12px;
    letter-spacing: 1px;
    padding: 1rem;
    border-radius: 0.25rem;
    border: none;
    /* font-weight: bold; */
}

.enquiryButtons {
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
}

.modal-open .modal {
    padding: 0 !important;
}

@media (max-width: 550px) {
    .modal.show .modal-dialog {
        position: relative !important;
        left: 0rem !important;
        top: 0rem;
    }
}
