/* .navbar {
  padding: 0rem 1rem !important;
} */

#productdropdown-mobile {
  display: none;
  /* max-width: 18rem; */
  margin: auto;
}

.productMobile-hide {
  display: none;
}

#exploredropdown-mobile {
  display: none;
  /* max-width: 18rem; */
  margin: auto;
}

.exploreMobile-hide {
  display: none;
}

#noticedropdown-mobile {
  display: none;
  /* max-width: 18rem; */
  margin: auto;
}

.noticeMobile-hide {
  display: none;
}

.globalTopMargin {
  margin-top: 7.75rem !important;
}

.firstNav > .fixed-top {
  height: 2rem;
  margin: auto;
  /* color: rgb(227, 26, 82); */
  background-color: white;
}

.contactFnav {
  margin: auto;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: orange !important;
}
.secondNav > .fixed-top {
  background-color: white !important;
  padding-top: 0;
  padding-bottom: 0;
}
.secondNav > .navbar-light .navbar-nav .nav-link {
  color: black;
}
.secondNav > .bg-light {
  background: white !important;
}
.secondNav > .navbar-brand {
  padding: 0 !important;
}

.secondNav > .navbar-brand > img {
  width: 12rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.secondNav > .PayOnline {
  background-color: rgb(227, 26, 82) !important;
  border: none !important;
}

.secondNav > nav {
  margin-top: 0rem !important;
}

.fixed-top {
  background-color: rgb(253, 181, 21) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
.bg-light {
  background: white !important;
}
.navbar-brand {
  padding: 0 !important;
}

.navbar-brand > img {
  width: 18rem;
}

.PayOnline {
  background-color: rgb(227, 26, 82) !important;
  border: none !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fdb515 !important;
  color: white !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fdb515 !important;
  color: white !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: black !important;
}

/* nav {
  margin-top: 5.5rem !important;
} */

/* Sticky Left */

.sticky-left {
  position: fixed;
  top: 20rem;
  left: -40px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
}

.enquire {
  writing-mode: tb-rl;
  margin: auto;
  padding: 0.25rem;
  color: white;
  letter-spacing: 0.25rem;
  transform: rotate(180deg);
}
.social-left {
  list-style: none;
}
.social-left li {
  padding: 10px 10px;
  transition: all 0.5s ease-in-out;
}
.social-left li:hover {
  margin-right: -5rem;
  box-shadow: 2px 5px 10px grey;
  transition: all 0.5s ease-in-out;
}
.social-left li:hover .fa {
  margin-left: 20px;
}
.email-left {
  background-color: #fdb515;
  border-bottom-right-radius: 1rem;
}
.email-left-others {
  background-color: #fdb515;
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
}
.home-left {
  background-color: #e31a52;
  color: white;
  border-top-right-radius: 1rem;
}
/* Sticky Right */

/* Sticky Sidebar */
.sticky-right {
  position: fixed;
  top: 20rem;
  right: 0px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
}
.social-right {
  list-style: none;
}
.social-right li {
  padding: 10px 10px;
  transition: all 0.2s ease-in-out;
}
.fb {
  background-color: #3c5a98;
  padding: 0.25rem;
  transition: all 0.5s ease-in-out;
  width: 3rem;
  border-top-left-radius: 1rem;
}
.call {
  color: white;
  background-color: green;
  padding: 0.25rem;
  transition: all 0.5s ease-in-out;
  width: 3rem;
}
.call > a {
  color: white;
}
.call > a:hover {
  color: white;
}

.tollfree {
  color: white;
  background-color: orange;
  padding: 0.25rem;
  transition: all 0.5s ease-in-out;
  width: 3rem;
  border-bottom-left-radius: 1rem;
}
.tollfree > a {
  color: white;
}
.tollfree > a:hover {
  color: white;
}

/* .fb:hover {
  margin-right: 0.25rem;
  box-shadow: 2px 5px 10px grey;
  transition: all 0.5s ease-in-out;
} */

.twitter {
  padding: 0.25rem;
  background-color: #1ea1f2;
  transition: all 0.5s ease-in-out;
  width: 3rem;
}

/* .twitter:hover {
  margin-right: 0.25rem;
  box-shadow: 2px 5px 10px grey;
  transition: all 0.5s ease-in-out;
} */

/* Sticky Sidebar ends */

/* @media only screen and (max-width: 500px) {
  .navbar-brand > img {
    width: 10rem;
  }
} */

.newsSection {
  color: rgb(227, 26, 82) !important;
}

@media (min-width: 992px) {
  .secondNav > .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

@media (max-width: 992px) {
  #productdropdown-mobile {
    display: block;
  }
  .productMobile-hide {
    display: block;
  }
  #productdropdown-desktop {
    display: none;
  }
  .productdropdown-desktop {
    display: none !important;
  }
  .productDesktop-hide {
    display: none;
  }

  #exploredropdown-mobile {
    display: block;
  }
  .exploreMobile-hide {
    display: block;
  }
  #exploredropdown-desktop {
    display: none;
  }
  .exploredropdown-desktop {
    display: none !important;
  }
  .exploreDesktop-hide {
    display: none;
  }

  #noticedropdown-mobile {
    display: block;
  }
  .noticeMobile-hide {
    display: block;
  }
  #noticedropdown-desktop {
    display: none;
  }
  .noticedropdown-desktop {
    display: none !important;
  }
  .noticeDesktop-hide {
    display: none;
  }
}

@media (max-width: 767px) {
  .firstNav {
    display: none;
  }

  .secondNav > nav {
    margin-top: 0rem !important;
  }
  .globalTopMargin {
    margin-top: 7.5rem !important;
  }
  .secondNav > .navbar-brand > img {
    width: 9rem;
    padding: 1rem;
  }
}
.navbar-nav .dropdown-menu {
  max-height: 20rem;
  overflow-y: scroll;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  overflow: auto;
}