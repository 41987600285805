.aboutImageContainer {
    width: 100%;
    max-width: 50rem;
    height: 100%;
    margin: auto;
}

.aboutImageContainer:hover .overlay {
    opacity: 1;
}

.aboutImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
}

.overlay {
    position: absolute;
    top: 0;
    color: #f1f1f1;
    font-weight: 600;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    padding: 2rem;
    text-align: center;
    left: 0;
    right: 0;
}
