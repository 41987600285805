@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 2px;
  /* font-weight: 600 !important; */
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

input:focus {
  outline: 0px !important;
}

/* Wrapper and Font sizes Start */
.Base-wrapper {
  width: 90%;
  margin: 2rem auto;
}

.headings {
  font-size: 1.75rem !important;
  font-weight: bold !important;
  color: #e31a52;
}

.mid-size-content {
  font-size: 1.25rem !important;
  font-weight: bold !important;
}

.lower-mid-size-content {
  font-size: 1rem !important;
}

.small-size-content {
  font-size: 1rem !important;
}

.cursor-pointer {
  cursor: pointer;
}
/* Wrapper and Font sizes End */
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.chatbox {
  position: fixed;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: orange;
  bottom: 30rem;
  right: 1rem;
  height: 4rem;
  line-height: 4rem;
  border-radius: 7rem;
  cursor: pointer;
  z-index: 1000;
}

.chatButton {
  position: fixed;
  width: 4rem;
  background: orange;
  bottom: 2rem;
  right: 2rem;
  height: 4rem;
  line-height: 5rem;
  border-radius: 7rem;
  cursor: pointer;
}

.chatIcon {
  font-size: 2rem;
  color: white;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup chat - hidden by default */
.form-popup {
  /* display: none; */
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #fdb515;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  /* opacity: 0.8; */
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: #e31a52;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(0.95em + 0.1875rem) center !important;
}

@media only screen and (max-width: 1366px) and (min-width: 320px) {
  .headings {
    font-size: 1.5rem !important;
    font-weight: bold !important;
  }

  .mid-size-content {
    font-size: 1rem !important;
  }

  .lower-mid-size-content {
    font-size: 0.9rem !important;
  }

  .small-size-content {
    font-size: 0.75rem !important;
  }
}

/* Document Layer Styles Start */

.DocumentList-wrapper {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
}

.FAIcon {
  width: 4rem !important;
  height: 4rem !important;
}

.DocumentIcon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 2rem;
  border-radius: 5rem;
  background: #e31a52;
  color: white;
  transition: 0.4s ease;
  box-shadow: 0px 14px 20px -6px rgb(0 0 0 / 20%);
}

.DocumentIcon:hover {
  -webkit-filter: drop-shadow(0px 5px 10px #00000080);
          filter: drop-shadow(0px 5px 10px #00000080);
  transition: 0.4s ease;
  border-radius: 5rem;
  /* background: rgb(227, 26, 82); */
  background: rgb(253 181 21);
  padding: 2rem;
  color: white;
}

.DocumentListTitle {
  margin: 1rem;
}
.Card-style {
  min-height: auto;
  width: 30%;
  margin: 0.5rem auto;
  transition: 1s ease;
}
.DocumentList-container > p {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 850px) {
  .DocumentList-wrapper {
    display: block;
  }
  .Card-style {
    width: 100%;
    margin: 1em auto;
  }
}
/* Document Layer Styles End */

.Carousel-applyButton {
  background-color: rgb(253, 181, 21);
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1rem;
  font-weight: bold;
  padding: 1rem;
  border-radius: 0.25rem;
  border: none;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
}

.carousel-fade .carousel-inner .item {
  transition-property: opacity !important;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0 !important;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1 !important;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0 !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
          transform: translate3d(0, 0, 0) !important;
}
.carousel-fade .carousel-control {
  z-index: 2 !important;
}

/* .pickgradient {
  position: relative;
  display: inline-block;
}
.pickgradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.2), rgba(227, 26, 82, 0.8));
  background: -webkit-gradient(
    linear,
    color-stop(0%, rgba(0, 0, 0, 0.2)),
    color-stop(45%, rgba(0, 0, 0, 0.2)),
    color-stop(100%, rgba(227, 26, 82, 0.8))
  );
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
} */

img {
  /* display: block; */
  /* height: 200px; */
  width: auto;
}

.carousel-item {
  max-height: 40rem;
}

.Carousel-image {
  width: 100%;
  margin: auto;
  object-fit: cover;
  object-position: center;
}

.Carousel-title {
  border: solid 2px #00000000;
  background: #00000090;
  border-radius: 0.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: -1.25rem;
  text-align: left;
  left: -0.6em;
}
.carousel-indicators li {
  box-sizing: content-box;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 10px !important;
  height: 10px !important;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: content-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  border: 0.25em solid #ffffff10 !important;
  border-radius: 2.25rem !important;
}

.carousel-indicators .active {
  border: 0.25em solid !important;
  border-radius: 2.25rem !important;
}

@media only screen and (min-width: 850px) {
  .Carousel-title {
    border: solid 2px #00000000;
    background: #00000060;
    border-radius: 0.25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: -5.5rem;
    padding: 0.5rem;
    text-align: left;
  }
}
@media only screen and (min-width: 1920px){
  .Carousel-image {
    width: 100%;
    height: 47rem;
    margin: auto;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1400px) {
  .Carousel-image {
    width: 100%;
    height: 47rem;
    margin: auto;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 767px) {
  .Carousel-image {
    width: 100%;
    height: 27rem;
    margin: auto;
  }
}

.Base-wrapper {
  height: 100%;
  /* margin-bottom: 0; */
}
.StatBackground {
  height: 32rem;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.StatIcon {
  width: 4.5rem;
}
.StatValue {
  font-size: 3rem;
}
.StatList-wrapper {
  padding: 2rem;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  color: white;
}
.StatTitle {
  font-size: 1.5rem;
}

.StatData > p {
  margin: 0 !important;
}
.StatData > h3 {
  margin: 0 !important;
}

@media only screen and (max-width: 850px) {
  .StatList-wrapper {
    display: block;
  }
  .StatTitle {
    font-size: 1.25rem;
  }
  .StatBackground {
    height: 58rem;
  }
  .StatValue {
    font-size: 2.5rem;
  }
  .StatIcon {
    width: 4.5rem;
    margin-top: 3rem;
  }
}

.RepcoBranches {
  height: 20rem;
  line-height: 20rem;
  color: white;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: 1s ease;
  cursor: pointer;
}

.branch-custom-row {
  width: 50%;
}

.RepcoBranches > a {
  color: white;
}

.RepcoBranches > a:hover {
  color: white !important;
}

.RepcoBranches a:link {
  text-decoration: none;
}

.RepcoBranches a:visited {
  text-decoration: none;
}

.RepcoBranches a:hover {
  text-decoration: underline;
}

.RepcoBranches a:active {
  text-decoration: underline;
}

.SatelliteCenters {
  height: 20rem;
  line-height: 20rem;
  color: white;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: 1s ease;
  cursor: pointer;
}

.SatelliteCenters > a {
  color: white;
}

.SatelliteCenters > a:hover {
  color: white !important;
}

.SatelliteCenters > a:link {
  text-decoration: none;
}

.SatelliteCenters > a:visited {
  text-decoration: none;
}

.SatelliteCenters > a:hover {
  text-decoration: underline;
}

.SatelliteCenters > a:active {
  text-decoration: underline;
}

.RepcoBranches:hover {
  background: black;
  color: white;
  transition: 1s ease;
}

/* .SatelliteCenters:hover {
  background: black;
  color: white;
  transition: 1s ease;
} */

.BranchCardImage {
  min-height: 16rem !important;
  min-width: 11rem;
  object-fit: cover;
  height: 16rem;
}

.alternateColor .card {
  height: 27rem;
  font-weight: 600;
}
a:hover {
  color: black !important;
  text-decoration: none !important;
}

a {
  color: black !important;
  text-decoration: none !important;
  background-color: transparent;
}

.alternateColor:nth-child(odd) .card:hover {
  background: #fdb515;
  color: white;
  cursor: pointer;
  transition: 0.25s ease;
}

/* .card:nth-child(1n-1):hover {
  background: #e31a52;
  color: white;
  cursor: pointer;
  transition: 0.5s ease;
} */

.card-body {
  -webkit-flex: none !important;
          flex: none !important;
  min-height: 1px;
  padding: 1.25rem;
  margin: auto;
  padding-top: 0 !important;
}

.BranchNetworkHeading {
  margin: 3rem 0rem;
}
.BranchInputSet {
  margin-bottom: 3rem;
}
.BranchStateInput {
  border: 2px solid #fdb515;
  border-radius: 30px;
  color: black !important;
  padding: 0.5rem 1.25rem;
  margin-right: 1rem;
  width: 45%;
}
.BranchCityInput {
  border: 2px solid #fdb515;
  border-radius: 30px;
  color: black !important;
  padding: 0.5rem 1.25rem;
  width: 45%;
}
.SATCenterButton {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05),
    0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
  height: 3rem;
  background: #fdb515 !important;
  border: none !important;
  color: black !important;
  font-weight: 500 !important;
}

.font-tint {
  color: rgb(227, 26, 82);
}

@media only screen and (max-width: 522px) {
  .BranchStateInput {
    margin-bottom: 1rem;
    margin-right: 0rem;
  }
  .branch-custom-row {
    width: 80%;
  }
}

.form-control {
  height: 4rem !important;
}

.FooterHeadings {
  color: #e31a52;
  margin: 1rem 0rem;
  font-weight: 600;
}

.Footer-wrapper {
  background-color: #ffffff;
}
.Footer-wrapper > .Base-wrapper {
  background-color: #ffffff;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.footer {
  text-align: left;
  padding-top: 1rem;
  color: black;
}

.row {
  margin: 0 !important;
}
.Subscribe-button {
  border: none !important;
  border-radius: 1rem !important;
  background-color: rgb(227, 26, 82) !important;
  margin-left: 0.5rem;
}

.list-group-item {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0.5rem !important;
  border: none !important;
  /* padding-left: 0 !important; */
  background-color: #fff !important;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #000;
  border: 1px solid rgba(0, 0, 0, 0.125);
  letter-spacing: 2px;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  color: black;
  letter-spacing: 2px;
}

.lastSection {
  padding: 0 !important;
}

.FooterHeading {
}

hr {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  border: 2px solid rgb(253 181 21) !important;
  border-top: 1px !important;
  width: 50% !important;
}

.FooterProductList > a {
  color: black;
}
.FooterProductList > a:hover {
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 850px) {
  .FooterHeading {
  }
}

.EnquiryFormBase {
    position: absolute;
    background: white;
    padding: 2rem;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    left: 6rem;
    top: -8rem;
    border-radius: 0.25rem;
    min-width: 30rem;
    overflow: auto;
    max-height: 35rem;
    box-shadow: -1px 3px 16px 12px rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: -1px 3px 16px 12px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -1px 3px 16px 12px rgba(0, 0, 0, 0.26);
    transition: 1s ease-in;
}

.okButton {
    margin: auto;
    display: block;
    min-width: 6rem;
    background-color: rgb(253, 181, 21);
    /* text-transform: uppercase; */
    color: white;
    text-align: center;
    line-height: 12px;
    letter-spacing: 1px;
    padding: 1rem;
    border-radius: 0.25rem;
    border: none;
}

/* .modal.show .modal-dialog {
    position: absolute !important;
    left: 3rem !important;
    top: 5rem;
} */

.enquiryApplyButton {
    background-color: rgb(253, 181, 21);
    /* text-transform: uppercase; */
    color: white;
    text-align: center;
    line-height: 12px;
    letter-spacing: 1px;
    padding: 1rem;
    border-radius: 0.25rem;
    border: none;
    /* font-weight: bold; */
}

.enquiryCloseButton {
    background-color: rgb(227, 26, 82);
    /* text-transform: uppercase; */
    color: white;
    text-align: center;
    line-height: 12px;
    letter-spacing: 1px;
    padding: 1rem;
    border-radius: 0.25rem;
    border: none;
    /* font-weight: bold; */
}

.enquiryButtons {
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
}

.modal-open .modal {
    padding: 0 !important;
}

@media (max-width: 550px) {
    .modal.show .modal-dialog {
        position: relative !important;
        left: 0rem !important;
        top: 0rem;
    }
}

/* .navbar {
  padding: 0rem 1rem !important;
} */

#productdropdown-mobile {
  display: none;
  /* max-width: 18rem; */
  margin: auto;
}

.productMobile-hide {
  display: none;
}

#exploredropdown-mobile {
  display: none;
  /* max-width: 18rem; */
  margin: auto;
}

.exploreMobile-hide {
  display: none;
}

#noticedropdown-mobile {
  display: none;
  /* max-width: 18rem; */
  margin: auto;
}

.noticeMobile-hide {
  display: none;
}

.globalTopMargin {
  margin-top: 7.75rem !important;
}

.firstNav > .fixed-top {
  height: 2rem;
  margin: auto;
  /* color: rgb(227, 26, 82); */
  background-color: white;
}

.contactFnav {
  margin: auto;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: orange !important;
}
.secondNav > .fixed-top {
  background-color: white !important;
  padding-top: 0;
  padding-bottom: 0;
}
.secondNav > .navbar-light .navbar-nav .nav-link {
  color: black;
}
.secondNav > .bg-light {
  background: white !important;
}
.secondNav > .navbar-brand {
  padding: 0 !important;
}

.secondNav > .navbar-brand > img {
  width: 12rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.secondNav > .PayOnline {
  background-color: rgb(227, 26, 82) !important;
  border: none !important;
}

.secondNav > nav {
  margin-top: 0rem !important;
}

.fixed-top {
  background-color: rgb(253, 181, 21) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
.bg-light {
  background: white !important;
}
.navbar-brand {
  padding: 0 !important;
}

.navbar-brand > img {
  width: 18rem;
}

.PayOnline {
  background-color: rgb(227, 26, 82) !important;
  border: none !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fdb515 !important;
  color: white !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fdb515 !important;
  color: white !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: black !important;
}

/* nav {
  margin-top: 5.5rem !important;
} */

/* Sticky Left */

.sticky-left {
  position: fixed;
  top: 20rem;
  left: -40px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
}

.enquire {
  -webkit-writing-mode: tb-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: tb-rl;
  margin: auto;
  padding: 0.25rem;
  color: white;
  letter-spacing: 0.25rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.social-left {
  list-style: none;
}
.social-left li {
  padding: 10px 10px;
  transition: all 0.5s ease-in-out;
}
.social-left li:hover {
  margin-right: -5rem;
  box-shadow: 2px 5px 10px grey;
  transition: all 0.5s ease-in-out;
}
.social-left li:hover .fa {
  margin-left: 20px;
}
.email-left {
  background-color: #fdb515;
  border-bottom-right-radius: 1rem;
}
.email-left-others {
  background-color: #fdb515;
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
}
.home-left {
  background-color: #e31a52;
  color: white;
  border-top-right-radius: 1rem;
}
/* Sticky Right */

/* Sticky Sidebar */
.sticky-right {
  position: fixed;
  top: 20rem;
  right: 0px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
}
.social-right {
  list-style: none;
}
.social-right li {
  padding: 10px 10px;
  transition: all 0.2s ease-in-out;
}
.fb {
  background-color: #3c5a98;
  padding: 0.25rem;
  transition: all 0.5s ease-in-out;
  width: 3rem;
  border-top-left-radius: 1rem;
}
.call {
  color: white;
  background-color: green;
  padding: 0.25rem;
  transition: all 0.5s ease-in-out;
  width: 3rem;
}
.call > a {
  color: white;
}
.call > a:hover {
  color: white;
}

.tollfree {
  color: white;
  background-color: orange;
  padding: 0.25rem;
  transition: all 0.5s ease-in-out;
  width: 3rem;
  border-bottom-left-radius: 1rem;
}
.tollfree > a {
  color: white;
}
.tollfree > a:hover {
  color: white;
}

/* .fb:hover {
  margin-right: 0.25rem;
  box-shadow: 2px 5px 10px grey;
  transition: all 0.5s ease-in-out;
} */

.twitter {
  padding: 0.25rem;
  background-color: #1ea1f2;
  transition: all 0.5s ease-in-out;
  width: 3rem;
}

/* .twitter:hover {
  margin-right: 0.25rem;
  box-shadow: 2px 5px 10px grey;
  transition: all 0.5s ease-in-out;
} */

/* Sticky Sidebar ends */

/* @media only screen and (max-width: 500px) {
  .navbar-brand > img {
    width: 10rem;
  }
} */

.newsSection {
  color: rgb(227, 26, 82) !important;
}

@media (min-width: 992px) {
  .secondNav > .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

@media (max-width: 992px) {
  #productdropdown-mobile {
    display: block;
  }
  .productMobile-hide {
    display: block;
  }
  #productdropdown-desktop {
    display: none;
  }
  .productdropdown-desktop {
    display: none !important;
  }
  .productDesktop-hide {
    display: none;
  }

  #exploredropdown-mobile {
    display: block;
  }
  .exploreMobile-hide {
    display: block;
  }
  #exploredropdown-desktop {
    display: none;
  }
  .exploredropdown-desktop {
    display: none !important;
  }
  .exploreDesktop-hide {
    display: none;
  }

  #noticedropdown-mobile {
    display: block;
  }
  .noticeMobile-hide {
    display: block;
  }
  #noticedropdown-desktop {
    display: none;
  }
  .noticedropdown-desktop {
    display: none !important;
  }
  .noticeDesktop-hide {
    display: none;
  }
}

@media (max-width: 767px) {
  .firstNav {
    display: none;
  }

  .secondNav > nav {
    margin-top: 0rem !important;
  }
  .globalTopMargin {
    margin-top: 7.5rem !important;
  }
  .secondNav > .navbar-brand > img {
    width: 9rem;
    padding: 1rem;
  }
}
.navbar-nav .dropdown-menu {
  max-height: 20rem;
  overflow-y: scroll;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  overflow: auto;
}
.CalculatorHeadings {
  color: black;
  text-align: left;
  width: 100%;
  font-weight: bold;
}

/* .LoanAmount {
  float: right;
  max-width: 7rem;
  text-align: right;
  border: none;
  border-radius: 0.25rem;
  margin-top: 1rem;
  color: black;
  font-weight: bold;
} */

/* .rc-slider strong {
} */

.rc-slider-rail {
  position: absolute;
  width: 100%;
  border-radius: 6px;
  background-color: lightgrey !important;
  height: 10px;
}

.emiSelectedValues {
  /* background: #fdb515; */
  color: #fdb515;
  font-weight: bold;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  /* -webkit-text-stroke: white 0.026px; */
}

.rc-slider-handle {
  border-color: black;
  border-radius: 2.25rem !important;
  height: 24px !important;
  width: 24px !important;
  margin-top: -7px !important;
  background-color: rgb(253, 181, 21);
  left: 14.1414%;
  right: auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.rc-slider-handle:active {
  border-color: black;
  box-shadow: none !important;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.rc-slider-dot {
  bottom: -6px !important;
  margin-left: -4px !important;
  width: 10px !important;
  height: 10px !important;
  border-color: #00000080 !important;
}

.rc-slider-dot-active {
  border-color: #000 !important;
}

.rc-slider-mark-text {
  font-size: 1rem;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #000 !important;
  font-weight: 600 !important;
}

.LoanAmount {
  margin: 0 !important;
  float: right;
  max-width: 6rem;
  padding-right: 0.5rem;
  text-align: right;
  border: none;
  border-radius: 0rem;
  margin-top: 1rem;
  color: black;
  font-weight: bold;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.LoanTenure {
  margin: 0 !important;
  float: right;
  max-width: 3rem;
  padding-right: 0.5rem;
  text-align: right;
  border: none;
  border-radius: 0rem;
  margin-top: 1rem;
  color: black;
  font-weight: bold;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.LoanPercentage {
  float: right;
  max-width: 3.5rem;
  text-align: right;
  border: none;
  color: black;
  font-weight: bold;
  background-color: white;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.CalculatorLabelsNormalCol {
  margin: auto;
  /* max-width: 3rem; */
  padding: 0 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.25rem !important;
}

.CalculatorNormalLabels {
  float: right;
  background: white;
  line-height: 1.2rem;
  font-weight: bold;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000;
  padding: 0.225rem;
}

.CalculatorNormalLabelsRight {
  float: right;
  background: white;
  line-height: 1.125rem;
  font-weight: bold;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000;
  padding: 0.225rem;
  font-size: 0.7rem;
}

.CalculatorNormalLabelLeft {
  float: right;
  background: white;
  line-height: 1.2rem;
  font-weight: bold;
  /* padding-right: 0.5rem; */
  padding-left: 0.5rem;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  color: #000;
  padding: 0.225rem;
}

.CalculatoValueCol {
  left: 1rem;
}

.CalculatorLabelsCol {
  margin: auto;
  max-width: 3rem;
}

.CalculatorLabels {
  margin: auto;
  max-width: 3rem;
  text-align: center;
  border: none;
  color: white;
  font-weight: bold;
  background-color: #e31a52;
  line-height: 2.4rem;
  margin-right: 0.90874997px;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.CalculatorModule {
  color: black;
  width: 80%;
  border: 7px solid #fdb515;
  border-radius: 0.25rem;
  padding-left: 10% !important;
  padding-right: 10% !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.EmiValue {
  /* border-left: 2px solid rgba(0, 0, 0, 0.1); */
  /* padding-left: 3rem !important; */
}
.EmiCalculatorSliders {
  padding-right: 3rem !important;
}
.EmiResult {
  margin-top: 4rem;
  color: rgb(255 255 255);
  background: #fdb515;
  /* border-radius: 0.25rem; */
  padding: 0.25rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.doughnut-mobile {
  display: none !important;
}

.rupee-symbol {
}

/* .rc-slider-mark-text {
  display: none !important;
} */

@media only screen and (max-width: 575px) {
  .rc-slider-mark-text {
    display: none !important;
  }
  .EmiValue {
    padding-left: 1rem !important;
  }

  .EmiCalculatorSliders {
    padding-right: 0rem !important;
  }

  .doughnut-others {
    display: none !important;
  }
  .doughnut-mobile {
    display: block !important;
    background-color: rgb(227, 26, 82);
    width: 90%;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .CalculatorModule {
    margin-bottom: 0 !important;
    border: none;
    padding: 0 !important;
  }
}

.flip-card {
  background-color: white !important;
  width: 100%;
  height: 100%;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  /* object-fit: cover; */
  min-width: 11rem;
  min-height: 16rem;
  margin: auto;
  border-radius: 0.25rem !important;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform ease-in-out 1s;
  transition: transform ease-in-out 1s;
  transition: transform ease-in-out 1s, -webkit-transform ease-in-out 1s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem !important;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 0.25rem;
  min-height: 4rem;
}

.flip-card-front {
  background-color: white;
  color: black;
}

.flip-card-back {
  background-color: #ec1852;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  border-radius: 0.25rem;
}

.block-with-text {
  padding: 3rem;
  position: relative;
  text-align: left;
  margin: auto;
  text-overflow: ellipsis;
  height: 100%;
  line-height: 2rem;
  position: relative;
  overflow: hidden;
  height: 85%;
}
.block-with-text:before {
  content: "ᐅ";
  position: absolute;
  right: 4rem;
  bottom: 0rem;
}
.ProductList-wrapper {
  background-color: rgb(253, 181, 21) !important;
}
.ProductList-wrapper > .Base-wrapper {
  background-color: rgb(253, 181, 21) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 3rem;
}
.flip-card-link > p {
  color: black !important;
  text-decoration: none !important;
}
.flip-card-link > p:hover {
  text-decoration: none !important;
}

.productListTitle {
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.ProductContent {
  white-space: pre-wrap;
  text-align: left;
}

.cardlayout-margin {
  margin-bottom: 3rem !important;
}

.bsProductImage {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 0.25rem;
}

.Documents-Required > ol > li {
  margin: -0.5rem;
}

.card-title {
}

.product-list-card > .card-image-size {
  /* max-width: 20rem;
  max-height: 10rem;
  height: 10rem; */
  padding: 3rem;
  margin: auto;
  border-radius: 0.25rem !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.product-list-card > .card-img-overlay {
  border: none;
  border-radius: 0.25rem !important;
  transition: 1s ease;
  background-color: rgba(227, 26, 82);
  box-shadow: 0px 16px 19px -6px rgba(0, 0, 0, 0.34);
  color: white;
  transition: 1s ease;
}

.ProductLabelTop {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.product-list-card > .card-img-overlay:hover {
  background-color: white;
  /* box-shadow: 0px 16px 19px -6px rgba(0, 0, 0, 0.34); */
  color: black;
  transition: 1s ease;
}
/* .productListTitle{
	mar
} */
.breadcrumbs a {
  color: rgb(227, 26, 82) !important;
  padding-left: 1.125rem;
}

.breadcrumbs {
  font-weight: 500;
}

.Productsheading > p {
  color: #666;
  display: inline-block;
  margin: 0;
  /* text-transform: uppercase; */
}
.Productsheading > p:after {
  display: block;
  content: "";
  border-bottom: solid 3px rgb(227, 26, 82);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}
.Productsheading > p:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.Productsheading > p.fromRight:after {
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}
.Productsheading {
  margin-bottom: 6rem;
}
.perProductImage {
  max-height: 25rem;
  object-fit: cover;
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
}
.perProductImageOverlay {
  /* padding-top: 11rem !important; */
  color: white;
  font-weight: bold;
  text-align: left;
  width: 90%;
  margin: auto;
}
.perProductCardText {
  white-space: pre-line;
  text-align: left;
}
.product-text {
  text-align: left;
  margin: auto;
}
.ProductList-wrapper {
  background-color: rgb(253, 181, 21) !important;
}
.ProductList-wrapper > .Base-wrapper {
  background-color: rgb(253, 181, 21) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 3rem;
}
.ProductTitle {
  line-height: 35rem;
  color: white;
  font-weight: bold;
}

.tab-content > .active {
  opacity: 1 !important;
}

.ProductDetailsTab {
  width: 100%;
  /* padding-left: 1.125rem; */
  margin-bottom: 0 !important;
}

.nav-tabs {
  padding-bottom: 0rem;
  border-bottom: 2px solid #fdb515 !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.ProductDetailsTab > .nav-tabs > a {
  margin: auto;
  width: 25%;
  color: black;
  /* margin-bottom: 0; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  background: #e31a52 !important;
  border: none !important;
  border-radius: 0.25rem !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 1rem;
}

@media only screen and (max-width: 767px) {
  .ProductDetailsTab > .nav-tabs > a {
    margin: auto;
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .ProductDetailsTab > .nav-tabs > a {
    margin: auto;
    width: 100%;
  }
  .cardlayout-margin {
    margin-bottom: 0rem !important;
  }
}

.aboutImageContainer {
    width: 100%;
    max-width: 50rem;
    height: 100%;
    margin: auto;
}

.aboutImageContainer:hover .overlay {
    opacity: 1;
}

.aboutImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
}

.overlay {
    position: absolute;
    top: 0;
    color: #f1f1f1;
    font-weight: 600;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    padding: 2rem;
    text-align: center;
    left: 0;
    right: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: white !important;
    background: #fdb515 !important;
    border: none !important;
    border-radius: 0.25rem !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    /* padding: 0 !important; */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #fdb515 !important;
    font-weight: bold;
}

.pill-row {
    border-radius: 0.25rem;
    box-shadow: -1px 1px 20px 0px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: -1px 1px 20px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: -1px 1px 20px 0px rgba(0, 0, 0, 0.12);
}

.pill-column {
    border-right: 1px solid #fdb515;
}

.pdfHighlight {
    line-height: 2.5rem;
    color: #fdb515 !important;
    margin-right: 0.5rem;
}

.pdfHighlight:hover {
    color: #e31a52 !important;
}

.pdfHighlight-line-space {
    color: #fdb515 !important;
    margin-right: 0.5rem;
}

.pdfHighlight-line-space:hover {
    color: #e31a52 !important;
}

.tab-content {
    width: 100% !important;
}
.grid-container {
    margin-right: 10rem;
    margin-left: 10rem;
    display: grid;
    overflow: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
    grid-row-gap: 0 !important;
    padding: 10px;
}
/* .grid-item:hover {
    background: orange;
    border: none;
}
.grid-item:hover > p {
    color: white !important;
} */
.grid-item {
    /* border-radius: 0.25rem; */
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(77, 77, 77, 0.8);
    padding: 10px;
    font-size: 1rem;
    text-align: center;
}

.OtherModal >>> .modal.show .modal-dialog {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
}

@media (max-width: 1700px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 5rem;
        margin-left: 5rem;
    }
}

@media (max-width: 1600px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 5rem;
        margin-left: 5rem;
    }
}

@media (max-width: 1100px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 0rem;
        margin-left: 0rem;
        /* margin-right: 10rem;
        margin-left: 10rem; */
    }
}

@media (max-width: 600px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 0rem;
        margin-left: 0rem;
    }
}

@media (max-width: 400px) {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 10px;
        margin-right: 0rem;
        margin-left: 0rem;
    }
}

.announcementsBackground {
    background-image: url("http://134.209.154.158/backend/uploads/anouncement_2_2ab49bb4ce.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 25rem;
}

.announcements {
    /* color: #000; */
    line-height: 18rem;
}

.new-announcement {
    -webkit-animation: newAnnouncement;
            animation: newAnnouncement;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 0.8rem;
    color: white;
    font-weight: bold;
}
.important-announcement {
    -webkit-animation: importantAnnouncement;
            animation: importantAnnouncement;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    font-weight: bold;
}

@-webkit-keyframes importantAnnouncement {
    0% {
        color: #E31A52;
    }
    33% {
        color: black;
    }
    66% {
        color: black;
    }
    100% {
        color: #E31A52;
    }
}

@keyframes importantAnnouncement {
    0% {
        color: #E31A52;
    }
    33% {
        color: black;
    }
    66% {
        color: black;
    }
    100% {
        color: #E31A52;
    }
}

@-webkit-keyframes newAnnouncement {
    0% {
        background-color: red;
    }
    33% {
        background-color: orange;
    }
    100% {
        background-color: #e31a52;
    }
}

@keyframes newAnnouncement {
    0% {
        background-color: red;
    }
    33% {
        background-color: orange;
    }
    100% {
        background-color: #e31a52;
    }
}

@media only screen and (max-width: 575px) {
    .announcementsBackground {
        height: 15rem;
    }
    .announcements {
        /* color: #000; */
        line-height: 12rem;
    }
}


#photos {
  /* Prevent vertical gaps */

  -webkit-column-count: 3;
  -webkit-column-gap: 10px;
  -moz-column-count: 3;
  -moz-column-gap: 10px;
  column-count: 3;
  grid-column-gap: 10px;
  line-height: 7;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}
@media (max-width: 1600px) {
  #photos {
    -webkit-column-count: 4;
    column-count: 4;
    line-height: 6;
  }
}
@media (max-width: 1200px) {
  #photos {
    -webkit-column-count: 4;
    column-count: 4;
    line-height: 6;
  }
}
@media (max-width: 1000px) {
  #photos {
    -webkit-column-count: 3;
    column-count: 3;
    line-height: 8;
  }
}
@media (max-width: 800px) {
  #photos {
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 9;
  }
}
@media (max-width: 400px) {
  #photos {
    -webkit-column-count: 1;
    column-count: 1;
    line-height: 10;
  }
}

.pswp__caption__center {
  text-align: center !important;
  font-size: 2rem;
  margin-bottom: 2rem;
  max-width: 70%;
  line-height: 1;
}

.newsUpdatesBackground {
    background-image: url("https://images.pexels.com/photos/3944417/pexels-photo-3944417.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20rem;
}

.newsUpdates {
    /* color: #000; */
    line-height: 14rem;
}

.new-newsUpdate {
    -webkit-animation: newnewsUpdate;
            animation: newnewsUpdate;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 0.8rem;
    color: white;
    /* font-weight: bold; */
}
.important-newsUpdate {
    -webkit-animation: importantnewsUpdate;
            animation: importantnewsUpdate;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    /* font-weight: bold; */
}

/* MARQUEE */

.container {
    width: 100%;
    height: 16rem;
    margin: 0;
    overflow: hidden;
    background: #e9e9e9;
    position: relative;
    box-sizing: border-box;
}

.marquee {
    top: 6em;
    position: relative;
    box-sizing: border-box;
    -webkit-animation: marquee 5s linear infinite;
            animation: marquee 5s linear infinite;
}

.marquee:hover {
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}

/* Make it move! */
@-webkit-keyframes marquee {
    0% {
        top: 15em;
    }
    100% {
        top: -9em;
    }
}
@keyframes marquee {
    0% {
        top: 15em;
    }
    100% {
        top: -9em;
    }
}

/* Make it look pretty */
.microsoft .marquee {
    margin: 0;
    padding: 0 1em;
    line-height: 1.5em;
    font: 1em "Segoe UI", Tahoma, Helvetica, Sans-Serif;
}

.microsoft:before,
.microsoft::before,
.microsoft:after,
.microsoft::after {
    left: 0;
    z-index: 1;
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 2em;
    /* background-image: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0)); */
}

.microsoft:after,
.microsoft::after {
    bottom: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.microsoft:before,
.microsoft::before {
    top: 0;
}

/* MARQUEE ENDS */

@-webkit-keyframes importantnewsUpdate {
    0% {
        color: #e31a52;
    }
    33% {
        color: white;
    }
    66% {
        color: white;
    }
    100% {
        color: #e31a52;
    }
}

@keyframes importantnewsUpdate {
    0% {
        color: #e31a52;
    }
    33% {
        color: white;
    }
    66% {
        color: white;
    }
    100% {
        color: #e31a52;
    }
}

@-webkit-keyframes newnewsUpdate {
    0% {
        background-color: red;
    }
    33% {
        background-color: orange;
    }
    100% {
        background-color: #e31a52;
    }
}

@keyframes newnewsUpdate {
    0% {
        background-color: red;
    }
    33% {
        background-color: orange;
    }
    100% {
        background-color: #e31a52;
    }
}

@media only screen and (max-width: 575px) {
    .newsUpdatesBackground {
        height: 10rem;
    }
    .newsUpdates {
        /* color: #000; */
        line-height: 8rem;
    }
}

