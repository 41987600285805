.carousel-fade .carousel-inner .item {
  transition-property: opacity !important;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0 !important;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1 !important;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0 !important;
  transform: translate3d(0, 0, 0) !important;
}
.carousel-fade .carousel-control {
  z-index: 2 !important;
}

/* .pickgradient {
  position: relative;
  display: inline-block;
}
.pickgradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.2), rgba(227, 26, 82, 0.8));
  background: -webkit-gradient(
    linear,
    color-stop(0%, rgba(0, 0, 0, 0.2)),
    color-stop(45%, rgba(0, 0, 0, 0.2)),
    color-stop(100%, rgba(227, 26, 82, 0.8))
  );
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 45%, rgba(227, 26, 82, 0.8) 100%);
} */

img {
  /* display: block; */
  /* height: 200px; */
  width: auto;
}

.carousel-item {
  max-height: 40rem;
}

.Carousel-image {
  width: 100%;
  margin: auto;
  object-fit: cover;
  object-position: center;
}

.Carousel-title {
  border: solid 2px #00000000;
  background: #00000090;
  border-radius: 0.25rem;
  width: fit-content;
  position: absolute;
  top: -1.25rem;
  text-align: left;
  left: -0.6em;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px !important;
  height: 10px !important;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: content-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  border: 0.25em solid #ffffff10 !important;
  border-radius: 2.25rem !important;
}

.carousel-indicators .active {
  border: 0.25em solid !important;
  border-radius: 2.25rem !important;
}

@media only screen and (min-width: 850px) {
  .Carousel-title {
    border: solid 2px #00000000;
    background: #00000060;
    border-radius: 0.25rem;
    width: fit-content;
    position: absolute;
    top: -5.5rem;
    padding: 0.5rem;
    text-align: left;
  }
}
@media only screen and (min-width: 1920px){
  .Carousel-image {
    width: 100%;
    height: 47rem;
    margin: auto;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1400px) {
  .Carousel-image {
    width: 100%;
    height: 47rem;
    margin: auto;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 767px) {
  .Carousel-image {
    width: 100%;
    height: 27rem;
    margin: auto;
  }
}
