.CalculatorHeadings {
  color: black;
  text-align: left;
  width: 100%;
  font-weight: bold;
}

/* .LoanAmount {
  float: right;
  max-width: 7rem;
  text-align: right;
  border: none;
  border-radius: 0.25rem;
  margin-top: 1rem;
  color: black;
  font-weight: bold;
} */

/* .rc-slider strong {
} */

.rc-slider-rail {
  position: absolute;
  width: 100%;
  border-radius: 6px;
  background-color: lightgrey !important;
  height: 10px;
}

.emiSelectedValues {
  /* background: #fdb515; */
  color: #fdb515;
  font-weight: bold;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  /* -webkit-text-stroke: white 0.026px; */
}

.rc-slider-handle {
  border-color: black;
  border-radius: 2.25rem !important;
  height: 24px !important;
  width: 24px !important;
  margin-top: -7px !important;
  background-color: rgb(253, 181, 21);
  left: 14.1414%;
  right: auto;
  transform: translateX(-50%);
}

.rc-slider-handle:active {
  border-color: black;
  box-shadow: none !important;
  cursor: grabbing;
}

.rc-slider-dot {
  bottom: -6px !important;
  margin-left: -4px !important;
  width: 10px !important;
  height: 10px !important;
  border-color: #00000080 !important;
}

.rc-slider-dot-active {
  border-color: #000 !important;
}

.rc-slider-mark-text {
  font-size: 1rem;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #000 !important;
  font-weight: 600 !important;
}

.LoanAmount {
  margin: 0 !important;
  float: right;
  max-width: 6rem;
  padding-right: 0.5rem;
  text-align: right;
  border: none;
  border-radius: 0rem;
  margin-top: 1rem;
  color: black;
  font-weight: bold;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.LoanTenure {
  margin: 0 !important;
  float: right;
  max-width: 3rem;
  padding-right: 0.5rem;
  text-align: right;
  border: none;
  border-radius: 0rem;
  margin-top: 1rem;
  color: black;
  font-weight: bold;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.LoanPercentage {
  float: right;
  max-width: 3.5rem;
  text-align: right;
  border: none;
  color: black;
  font-weight: bold;
  background-color: white;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.CalculatorLabelsNormalCol {
  margin: auto;
  /* max-width: 3rem; */
  padding: 0 !important;
  width: fit-content;
  margin-right: 0.25rem !important;
}

.CalculatorNormalLabels {
  float: right;
  background: white;
  line-height: 1.2rem;
  font-weight: bold;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000;
  padding: 0.225rem;
}

.CalculatorNormalLabelsRight {
  float: right;
  background: white;
  line-height: 1.125rem;
  font-weight: bold;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000;
  padding: 0.225rem;
  font-size: 0.7rem;
}

.CalculatorNormalLabelLeft {
  float: right;
  background: white;
  line-height: 1.2rem;
  font-weight: bold;
  /* padding-right: 0.5rem; */
  padding-left: 0.5rem;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  color: #000;
  padding: 0.225rem;
}

.CalculatoValueCol {
  left: 1rem;
}

.CalculatorLabelsCol {
  margin: auto;
  max-width: 3rem;
}

.CalculatorLabels {
  margin: auto;
  max-width: 3rem;
  text-align: center;
  border: none;
  color: white;
  font-weight: bold;
  background-color: #e31a52;
  line-height: 2.4rem;
  margin-right: 0.90874997px;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.CalculatorModule {
  color: black;
  width: 80%;
  border: 7px solid #fdb515;
  border-radius: 0.25rem;
  padding-left: 10% !important;
  padding-right: 10% !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.EmiValue {
  /* border-left: 2px solid rgba(0, 0, 0, 0.1); */
  /* padding-left: 3rem !important; */
}
.EmiCalculatorSliders {
  padding-right: 3rem !important;
}
.EmiResult {
  margin-top: 4rem;
  color: rgb(255 255 255);
  background: #fdb515;
  /* border-radius: 0.25rem; */
  padding: 0.25rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.doughnut-mobile {
  display: none !important;
}

.rupee-symbol {
}

/* .rc-slider-mark-text {
  display: none !important;
} */

@media only screen and (max-width: 575px) {
  .rc-slider-mark-text {
    display: none !important;
  }
  .EmiValue {
    padding-left: 1rem !important;
  }

  .EmiCalculatorSliders {
    padding-right: 0rem !important;
  }

  .doughnut-others {
    display: none !important;
  }
  .doughnut-mobile {
    display: block !important;
    background-color: rgb(227, 26, 82);
    width: 90%;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .CalculatorModule {
    margin-bottom: 0 !important;
    border: none;
    padding: 0 !important;
  }
}
