/* Document Layer Styles Start */

.DocumentList-wrapper {
  margin: 0 auto;
  display: flex;
}

.FAIcon {
  width: 4rem !important;
  height: 4rem !important;
}

.DocumentIcon {
  width: fit-content;
  margin: auto;
  padding: 2rem;
  border-radius: 5rem;
  background: #e31a52;
  color: white;
  transition: 0.4s ease;
  -webkit-box-shadow: 0px 14px 20px -6px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0px 14px 20px -6px rgb(0 0 0 / 20%);
  box-shadow: 0px 14px 20px -6px rgb(0 0 0 / 20%);
}

.DocumentIcon:hover {
  filter: drop-shadow(0px 5px 10px #00000080);
  transition: 0.4s ease;
  border-radius: 5rem;
  /* background: rgb(227, 26, 82); */
  background: rgb(253 181 21);
  padding: 2rem;
  color: white;
}

.DocumentListTitle {
  margin: 1rem;
}
.Card-style {
  min-height: auto;
  width: 30%;
  margin: 0.5rem auto;
  transition: 1s ease;
}
.DocumentList-container > p {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 850px) {
  .DocumentList-wrapper {
    display: block;
  }
  .Card-style {
    width: 100%;
    margin: 1em auto;
  }
}
/* Document Layer Styles End */

.Carousel-applyButton {
  background-color: rgb(253, 181, 21);
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1rem;
  font-weight: bold;
  padding: 1rem;
  border-radius: 0.25rem;
  border: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
}
